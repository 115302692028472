import React from 'react'

import avatar from '../assets/images/avatar.jpg'

const Header = () => (
  <header id="header">
    <div className="inner">
      <img className="image avatar" src={avatar} alt="" />
      <h1>
        <strong>Reagan Deming Art</strong>
      </h1>
      <ul>
        <li>Personalized Portraits</li>
        <li>Prints</li>
        <li>Murals</li>
        <li>Custom Artwork</li>
        <li>Paint Parties!</li>
        <li>About Me</li>
      </ul>
      <button className="mobile, inner">
        <a className="mobile" href="#more">
          Learn More
        </a>
      </button>
    </div>
  </header>
)

export default Header
